import Observable from '../_assets/scripts/Observable';
import {MapConfig} from './map-config';

/**
 * @param {string} googleMapsApiKey
 * @param {NodeList} maps
 * @return GoogleMapsController
 * @constructor
 */
export const GoogleMapsController = (googleMapsApiKey, maps) => {
    if (!maps || !maps.length) {
        console.warn('no map elements provided to startGoogleMaps');
        return;
    }

    /**
     * @typedef {{
     *     googleMapsApiKey: string
     *     maps: NodeList
     * }} GoogleMapsController
     */
    return {
        googleMapsApiKey,
        maps,
    };
};

export const includeGoogleMaps = (apiKey) => {
    const existingScriptTag = document.querySelector('#gmap-include');

    if (existingScriptTag) {
        return existingScriptTag;
    }

    const scriptTag = document.createElement('SCRIPT');
    scriptTag.id = 'gmap-include';
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=&v=weekly`;
    document.querySelector('head').appendChild(scriptTag);

    return scriptTag;
}

/**
 * @param {GoogleMapsController} controller
 */
export const initGoogleMaps = (controller) => {
    if (!controller) {
        console.warn('no google maps controller found');
        return undefined;
    }

    const scriptTag = includeGoogleMaps(controller.googleMapsApiKey);

    scriptTag.onload = () => {
        Array.from(controller.maps).forEach(map => initMap(controller, map));
    };
};

/**
 * @param {GoogleMapsController} controller
 * @param {Element} gmap
 */
const initMap = (controller, gmap) => {
    const selectedPoiId = new Observable(undefined);

    const dataZoom = gmap.dataset.zoom ? Number(gmap.dataset.zoom) : undefined;
    const dataInfoWindowEnabled = gmap.dataset.infoWindowEnabled ? Boolean(gmap.dataset.infoWindowEnabled) : undefined;
    const dataStyle = gmap.dataset.style ? JSON.parse(gmap.dataset.style) : undefined;
    const dataInitialCenter = gmap.dataset.initialCenter ? JSON.parse(gmap.dataset.initialCenter) : undefined;
    const dataUseCustomMarker = gmap.dataset.useCustomMarker ? Boolean(gmap.dataset.useCustomMarker) : undefined;
    const dataDisableDefaultUi = gmap.dataset.disableDefaultUi ? Boolean(gmap.dataset.disableDefaultUi) : undefined;
    const dataControls = gmap.dataset.controls ? JSON.parse(gmap.dataset.controls) : undefined;
    const dataGestureHandling = gmap.dataset.gestureHandling ? gmap.dataset.gestureHandling : 'auto';
    const dataDraggableCursor = gmap.dataset.draggableCursor ? gmap.dataset.draggableCursor : undefined;
    const initialCenter = dataInitialCenter ? dataInitialCenter : MapConfig.initialCenter;
    const initialZoom = dataZoom ? dataZoom : MapConfig.initialZoom;
    const initialDisableDefaultUi = dataDisableDefaultUi ? dataDisableDefaultUi : MapConfig.disableDefaultUi;

    const pois = Array.from(gmap.querySelector('.pois').children);
    const target = gmap.querySelector('.gmap');
    const styledMapType = new google.maps.StyledMapType(dataStyle ? dataStyle : MapConfig.style);
    const unselectedIcon = {url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(MapConfig.unselectedIcon)};
    const selectedIcon = {url: 'data:image/svg+xml;charset=UTF-8;base64,' + btoa(MapConfig.selectedIcon)};

    const getPoiById = (id) => {
        return pois.find(poi => poi.id === id);
    };

    const registerPoiCloseHandler = (poi) => {
        const closeButton = poi.querySelector('button');
        closeButton.onclick = () => {
            selectedPoiId.setValue(undefined);
        };
    };

    const mapConfig = {
        center: initialCenter,
        zoom: initialZoom,
        disableDefaultUI: initialDisableDefaultUi,
        gestureHandling: dataGestureHandling,
        mapTypeControlOptions: {
            mapTypeIds: ['styled_map'],
        },
        scrollwheel: false,
        draggableCursor: dataDraggableCursor,
    };

    if (dataDraggableCursor) {
        mapConfig.draggableCursor = dataDraggableCursor;
    }

    if (dataControls) {
        Object.entries(dataControls).forEach((entry) => {
            const [key, value] = entry;
            mapConfig[key] = value;
        });
    }

    const map = new google.maps.Map(target, mapConfig);

    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
    const markers = [];

    pois.forEach(poi => {
        registerPoiCloseHandler(poi);
        const lng = Number(poi.dataset.lng);
        const lat = Number(poi.dataset.lat);

        const markerConfig = {
            position: {lat: lat, lng: lng},
            map,
            poiId: poi.id
        };
        if (dataUseCustomMarker) {
            markerConfig.icon = unselectedIcon;
        }

        const marker = new google.maps.Marker(markerConfig);

        if (dataInfoWindowEnabled) {
            marker.addListener('click', () => {
                selectedPoiId.setValue(marker.get('poiId'));
            });
            markers[poi.id] = marker;
        }
    });

    selectedPoiId.onChange((newId, oldId) => {
        if (newId !== oldId) {
            if (newId) {
                const marker = markers[newId];
                if (dataUseCustomMarker) {
                    marker.setIcon(selectedIcon);
                }

                const poi = getPoiById(newId);
                const lng = Number(poi.dataset.lng);
                const lat = Number(poi.dataset.lat);

                map.setCenter({lat: lat - 0.02, lng: lng});
                map.setZoom(12);
                poi.removeAttribute('style');
            }
            if (oldId) {
                const marker = markers[oldId];
                if (dataUseCustomMarker) {
                    marker.setIcon(unselectedIcon);
                }

                const poi = getPoiById(oldId);
                poi.style.display = 'none';
            }
            if (!newId) {
                if (dataUseCustomMarker) {
                    Object.values(markers).forEach(marker => marker.setIcon(unselectedIcon));
                }

                map.setCenter(initialCenter);
                map.setZoom(initialZoom);
            }
        }
    });
};
