import {NavMetaBase} from './NavMetaBase';
import {NavMetaSearchForm} from './NavMetaSearchForm';
import {NavMetaSearchToggleButton} from './NavMetaSearchToggleButton';
import {NavMetaDropdownEntry} from './NavMetaDropdownEntry';

export const NavMetaController = (() => {
    const root = document.querySelector('.base-meta-navigation');
    const navMetaEntries = document.querySelectorAll('.nav-meta-dropdown-entry');

    const toggleButton = NavMetaSearchToggleButton(root);
    NavMetaBase(root);
    NavMetaSearchForm(root, toggleButton);

    if (navMetaEntries.length) {
        navMetaEntries.forEach((entry) => {
            NavMetaDropdownEntry(entry);
        });
    }

    return undefined;
})();
