const dealerLocatorBlocks = document.querySelectorAll('.block-dealer-locator');

const translations = {
    'de-CH': {
        notValid: 'Bitte geben Sie eine gültige Postleitzahl ein.',
    },
    'fr-CH': {
        notValid: 'Veuillez saisir un code postal valide.',
    }
};

(() => {
    if (!dealerLocatorBlocks.length) {
        return undefined;
    }

    dealerLocatorBlocks.forEach((retailerBlock) => {
        const form = retailerBlock.querySelector('form');
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            if (form.checkValidity()) {
                const formData = new FormData(e.target);
                const value = formData.get('zip');
                form.action = `${form.action}?zip=${value}`;
                form.submit();
            }
        });
    });
})();
