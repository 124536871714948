import {chevronPosition, MobileNavListEntry} from "./MobileNavListEntry";
import Observable from "../../../_assets/scripts/Observable";
import {navStore} from "../../../_assets/scripts/stores";
import {MobileNavPagePortalLoginLinks} from "./MobileNavPagePortalLoginLinks";
import {MobileNavPageLanguageSelector} from "./MobileNavPageLanguageSelector";

export const MobileNavPage = ({data}) => {
    const pageToRemove = new Observable();

    if (!data) {
        return undefined;
    }

    const template = document.createElement('DIV');

    template.innerHTML = `
        <div class="px-3 py-5 nav-mobile-container-inner nav-mobile-container-enter">
                <form action="${window.sessionStorage.getItem('searchResultsUrl')}">
                    <div class="input-group input-group-sm">
                        <input
                            class="form-control border-end-0"
                            id="mobile-search"
                            type="search"
                            name="q"
                            aria-label="Mobile Search"
                            aria-hidden="true"
                            aria-describedby="mobile-search-button"
                        >
                        <button class="input-group-text bg-transparent" type="submit" id="mobile-search-button">
                            <i class="fas fa-search text-brand-black"></i>
                        </button>
                    </div>
                </form>
                <nav class="mt-5">
                    <ul>
                    </ul>
                </nav>
                <nav id="portal-login-links" class="mt-5">
                   <div class="text-center">
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </nav>
                <nav id="mobile-language-selector">
                </nav>
        </div>`;

    // const [pageWrapper] = template.children;
    const [page] = template.children;
    const [search, nav, portalLoginLinks, mobileLanguageSelector] = page.children;
    const [ul] = nav.children;

    const entry = data.data?.entries?.length ? data.data?.entries[0] : undefined;

    if (!entry) {
        return undefined;
    }

    if (entry.parent) {
        const parentHr = document.createElement('HR');
        parentHr.classList.add('mt-2', 'mb-5');

        const title = document.createElement('DIV');
        title.innerHTML = entry.title;
        title.classList.add('fw-bold', 'mb-3');

        const parent = MobileNavListEntry({
            id: entry.parent.id,
            title: entry.parent.title,
            url: entry.parent.url,
            chevronPos: chevronPosition.left,
            onClick: () => {
                if (!navStore.loading.getValue()) {
                    pageToRemove.setValue({
                        id: entry.parent.id,
                        sectionHandle: entry.parent.sectionHandle,
                        typeHandle: entry.parent.typeHandle,
                        page
                    });
                }
            },
        });

        const current = MobileNavListEntry({
            id: entry.id,
            title: window.sessionStorage.getItem('lang') === 'de-CH' ? 'Mehr erfahren' : 'En savoir plus',
            url: entry.url,
            skipOnClick: true,
            anchorClasses: entry.id === window.sessionStorage.getItem('entryId') ? ['fw-bold'] : []
        });

        const currentHr = document.createElement('HR');
        currentHr.classList.add('my-2');

        ul.appendChild(parent.element);
        ul.appendChild(parentHr);
        ul.appendChild(title);
        ul.appendChild(current.element);
        ul.appendChild(currentHr);
    }

    if (entry.children?.length) {
        const sortedChildren = [...entry.children].sort((a, b) => {
            if (a.children?.length && b.children?.length) {
                return 0;
            }

            if (!a.children?.length && !b.children?.length) {
                return 0;
            }

            if (a.children?.length && !b.children?.length) {
                return -1;
            }

            if (!a.children?.length && b.children?.length) {
                return 1;
            }
        });

        sortedChildren.forEach((e) => {
            if (e.children?.length) {
                const child = MobileNavListEntry({
                    id: e.id,
                    title: e.title,
                    url: e.url,
                    chevronPos: chevronPosition.right,
                    onClick: () => {
                        if (!navStore.loading.getValue()) {
                            navStore.load({
                                id: e.id,
                                sectionHandle: e.sectionHandle,
                                typeHandle: e.typeHandle
                            });
                        }
                    }
                });
                ul.appendChild(child.element);
            } else {
                const child = MobileNavListEntry({
                    id: e.id,
                    title: e.title,
                    url: e.url,
                    skipOnClick: true
                });
                ul.appendChild(child.element);
            }
            const hr = document.createElement('HR');
            hr.classList.add('my-2');

            ul.appendChild(hr);
        });
    }

    MobileNavPagePortalLoginLinks(portalLoginLinks);
    MobileNavPageLanguageSelector(mobileLanguageSelector);

    return {
        element: page,
        onPageRemove: pageToRemove.onChange
    };
};
