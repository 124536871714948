import FontFaceObserver from 'fontfaceobserver';

const typekitLink = document.createElement('LINK');
typekitLink.rel = 'stylesheet';
typekitLink.href = 'https://use.typekit.net/ubp2zda.css';
document.head.appendChild(typekitLink);

const roboto = new FontFaceObserver('roboto');
const robotoCondensed = new FontFaceObserver('roboto-condensed');

Promise.all([roboto.load(), robotoCondensed.load()])
        .then(() => {
            document.body.classList.add('fonts-loaded');
        })
        .catch((err) => {
            document.body.classList.add('fonts-loaded-error');
            console.warn('Some critical font are not available:', err);
        });
