export const NavMetaDropdownEntry = (entry) => {
    const control = entry.querySelector('.nav-meta-dropdown-entry-control');
    const content = entry.querySelector('.nav-meta-dropdown-entry-content');
    if (!(control && content)) {
        console.warn('A meta navigation dropdown entry is missconfigured');
        return undefined;
    }

    const collapseSubmenu = () => {
        content.ariaExpanded = 'false';
        content.classList.remove('expanded');
        entry.classList.remove('active');
    };

    control.onclick = () => {
        entry.classList.add('active');
        content.ariaExpanded = 'true';
        content.classList.add('expanded');
        content.focus();

        function checkBoundaries(e) {
            const withinBoundaries = e.composedPath().includes(content) || e.composedPath().includes(control);
            if (!withinBoundaries) {
                window.removeEventListener('click', checkBoundaries, false);
                collapseSubmenu();
            }
        }

        function checkEscape(e) {
            if (e.key === 'Esc' || e.key === 'Escape') {
                window.removeEventListener('keydown', checkEscape, false);
                collapseSubmenu();
            }
        }

        window.addEventListener('click', checkBoundaries, false);
        window.addEventListener('keydown', checkEscape, false);
    };
};
