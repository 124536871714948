export const MobileNavPageLanguageSelector = (root) => {
    const desktopLanguageSelector = document.querySelector('#language-selector');
    if (!desktopLanguageSelector) {
        console.warn('no desktop language selector template found');
        return undefined;
    }

    const ul = document.createElement('UL');
    const template = desktopLanguageSelector.cloneNode(true);
    template.removeAttribute('id');
    template.removeAttribute('class');
    ul.appendChild(template);

    root.appendChild(ul);
};
