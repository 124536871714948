export const entryId = window.sessionStorage.getItem('entryId');
export const entrySectionHandle = window.sessionStorage.getItem('entrySectionHandle');
export const entryTypeHandle = window.sessionStorage.getItem('entryTypeHandle');
export const newsAndEventsPageId = window.sessionStorage.getItem('newsAndEventsPageId');
export const entryLevel = window.sessionStorage.getItem('entryLevel');

export const isPagesSectionAndProductsType = ({section = entrySectionHandle, type = entryTypeHandle} = {}) => {
    return section === 'pages' && type === 'products';
};
export const isHomeSectionAndType = ({section = entrySectionHandle, type = entryTypeHandle} = {}) => {
    return section === 'pages' && type === 'home';
};
export const isNewsSectionAndType = ({section = entrySectionHandle, type = entryTypeHandle} = {}) => {
    return section === 'news' && type === 'news';
};
export const isEventSectionAndType = ({section = entrySectionHandle, type = entryTypeHandle} = {}) => {
    return section === 'events' && type === 'event';
};
export const isProductStructSectionAndDefaultType = ({section = entrySectionHandle, type = entryTypeHandle} = {}) => {
    return section === 'productStruct' && type === 'default';
};

export const isOutsideHomeStructure = !isHomeSectionAndType() && entryLevel && JSON.parse(entryLevel) === 1;
