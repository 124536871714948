import {getCookie, setCookie} from '../_assets/scripts/utils';
import Observable from "../_assets/scripts/Observable";

export const CookieConsentController = (overlay, settings = {}) => {
    const exceptions = window.sessionStorage.getItem('cookieBannerExceptions');

    if (exceptions && exceptions.length) {
        const decoded = JSON.parse(exceptions);
        if (decoded.includes(decodeURI(window.location.href))) {
            return undefined;
        }
    }

    const cookieConsentAccepted = new Observable(0);

    const predefinedSettings = {
        cookieBannerId: 'cookie-consent',
        cname: 'cookie-consent',
        cvalue: 0,
        exdays: 356
    };

    const mergedSettings = {
        ...predefinedSettings,
        ...settings
    };

    const cookieBanner = document.getElementById(mergedSettings.cookieBannerId);
    if (!cookieBanner) {
        console.warn('cookieConsentController: no cookie banner found');
        return undefined;
    }

    if (!overlay) {
        console.warn('cookieConsentController: no overlay found');
        return undefined;
    }

    const cookie = getCookie(mergedSettings.cookieBannerId);

    const showCookieBanner = () => {
        cookieBanner.style.pointerEvents = 'all';
        overlay.style.display = 'block';

        overlay.classList.add('fade-in-500');
        cookieBanner.classList.add('fade-in-500');

        overlay.classList.remove('d-none');
        cookieBanner.classList.remove('d-none');
    };

    const hideCookieBanner = () => {
        overlay.classList.remove('fade-in-500');
        cookieBanner.classList.remove('fade-in-500');

        overlay.classList.add('fade-out-500');
        cookieBanner.classList.add('fade-out-500');

        cookieBanner.addEventListener('animationend', () => {
            cookieBanner.classList.add('d-none');
        });

        overlay.addEventListener('animationend', () => {
            overlay.classList.add('d-none');
        });
    };

    if (cookie && Number(cookie)) {
        return undefined;
    } else {
        showCookieBanner();
    }

    cookieConsentAccepted.onChange((value) => {
        if (value) {
            setCookie(mergedSettings.cname, value, mergedSettings.exdays);
        }
    });

    return {
        cookieConsentAccepted,
        showCookieBanner,
        hideCookieBanner
    };
};

export const cookieConsentView = (cookieConsentCtrl, settings = {}) => {
    const predefinedSettings = {
        acceptButtonId: 'cookie-consent-accept',
    };

    const mergedSettings = {
        ...predefinedSettings,
        ...settings
    };

    const acceptButton = document.getElementById(mergedSettings.acceptButtonId);
    if (!acceptButton) {
        console.warn('cookieConsentView: no cookie consent accept button found');
        return undefined;
    }

    acceptButton.onclick = () => {
        cookieConsentCtrl.cookieConsentAccepted.setValue(1);
        cookieConsentCtrl.hideCookieBanner();
    };
};
