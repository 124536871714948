// Fonts
import '../_assets/scripts/fontface-loader';

// Lazysizes
import 'lazysizes';

// Popper JS
import '@popperjs/core';

// Smooth Scrolling Polifill
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

// Bootstrap
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';

// Shared
import '../_shared/cookie-consent';

// Custom
import './partials/navigation/nav-mobile';
import './partials/navigation/nav-meta';
import '/templates/_assets/scripts/cookie-consent-init';
import './base-content-height';
