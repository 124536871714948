export const viewportSize = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    xxl: 'xxl',
};

// if breakpoints are not set in the :root style, then the functions fall back to predefined values
export const rootBreakpoints = {
    xs: Number(getComputedStyle(document.documentElement).getPropertyValue('--xs-breakpoint').replace('px', '')),
    sm: Number(getComputedStyle(document.documentElement).getPropertyValue('--sm-breakpoint').replace('px', '')),
    md: Number(getComputedStyle(document.documentElement).getPropertyValue('--md-breakpoint').replace('px', '')),
    lg: Number(getComputedStyle(document.documentElement).getPropertyValue('--lg-breakpoint').replace('px', '')),
    xl: Number(getComputedStyle(document.documentElement).getPropertyValue('--xl-breakpoint').replace('px', '')),
    xxl: Number(getComputedStyle(document.documentElement).getPropertyValue('--xxl-breakpoint').replace('px', '')),
};

export const isValidZipCode = (zipcode) => {
    const re = /^\d{4}$/gi;
    return zipcode.match(re);
}

/**
 * @param {string} size
 * @returns {boolean}
 */
export const isViewPortMinWidth = (size) => {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    switch (size.toLowerCase()) {
        case viewportSize.xs:
            return vw >= (rootBreakpoints.xs ? rootBreakpoints.xs : 0);
        case viewportSize.sm:
            return vw >= (rootBreakpoints.sm ? rootBreakpoints.sm : 576);
        case viewportSize.md:
            return vw >= (rootBreakpoints.md ? rootBreakpoints.md : 768);
        case viewportSize.lg:
            return vw >= (rootBreakpoints.lg ? rootBreakpoints.lg : 992);
        case viewportSize.xl:
            return vw >= (rootBreakpoints.xs ? rootBreakpoints.xs : 1200);
        case viewportSize.xxl:
            return vw >= (rootBreakpoints.xs ? rootBreakpoints.xs : 1400);
    }
};

/**
 * @param {string} size
 * @returns {boolean}
 */
export const isViewPortMaxWidth = (size) => {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    switch (size.toLowerCase()) {
        case viewportSize.xs:
            return vw < (rootBreakpoints.xs ? rootBreakpoints.xs : 0);
        case viewportSize.sm:
            return vw < (rootBreakpoints.sm ? rootBreakpoints.sm : 576);
        case viewportSize.md:
            return vw < (rootBreakpoints.md ? rootBreakpoints.md : 768);
        case viewportSize.lg:
            return vw < (rootBreakpoints.lg ? rootBreakpoints.lg : 992);
        case viewportSize.xl:
            return vw < (rootBreakpoints.xs ? rootBreakpoints.xs : 1200);
        case viewportSize.xxl:
            return vw < (rootBreakpoints.xs ? rootBreakpoints.xs : 1400);
    }
};

export const getQueryParam = (name) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    return params[name];
};

export const setCookie = (cname, cvalue, exdays) => {
    const date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + date.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname) => {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

/**
 * @param {Element} elem
 * @returns {{top: number, left: number}}
 */
export const getElementCoords = (elem) => {
    let box = elem.getBoundingClientRect();

    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
    };
};

/**
 * Converts radian to degree
 * @param rad
 * @return {number}
 */
export const radToDeg = (rad) => {
    return (180 / Math.PI) * rad;
};

/**
 * Converts degree to radian
 * @param deg
 * @return {number}
 */
export const degToRad = (deg) => {
    return (Math.PI / 180) * deg;
};
