export const viewportSize = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    xxl: 'xxl',
};

// if breakpoints are not set in the :root style, then the functions fall back to predefined values
export const rootBreakpoints = {
    xs: Number(getComputedStyle(document.documentElement).getPropertyValue('--xs-breakpoint').replace('px', '')),
    sm: Number(getComputedStyle(document.documentElement).getPropertyValue('--sm-breakpoint').replace('px', '')),
    md: Number(getComputedStyle(document.documentElement).getPropertyValue('--md-breakpoint').replace('px', '')),
    lg: Number(getComputedStyle(document.documentElement).getPropertyValue('--lg-breakpoint').replace('px', '')),
    xl: Number(getComputedStyle(document.documentElement).getPropertyValue('--xl-breakpoint').replace('px', '')),
    xxl: Number(getComputedStyle(document.documentElement).getPropertyValue('--xxl-breakpoint').replace('px', '')),
};

/**
 * @param {string} size
 * @returns {boolean}
 */
export const isViewPortMinWidth = (size) => {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    switch (size.toLowerCase()) {
        case viewportSize.xs:
            return vw >= (rootBreakpoints.xs ? rootBreakpoints.xs : 0);
        case viewportSize.sm:
            return vw >= (rootBreakpoints.sm ? rootBreakpoints.sm : 576);
        case viewportSize.md:
            return vw >= (rootBreakpoints.md ? rootBreakpoints.md : 768);
        case viewportSize.lg:
            return vw >= (rootBreakpoints.lg ? rootBreakpoints.lg : 992);
        case viewportSize.xl:
            return vw >= (rootBreakpoints.xs ? rootBreakpoints.xl : 1200);
        case viewportSize.xxl:
            return vw >= (rootBreakpoints.xs ? rootBreakpoints.xxl : 1400);
    }
};

/**
 * @param {string} size
 * @returns {boolean}
 */
export const isViewPortMaxWidth = (size) => {
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    switch (size.toLowerCase()) {
        case viewportSize.xs:
            return vw < (rootBreakpoints.xs ? rootBreakpoints.xs : 0);
        case viewportSize.sm:
            return vw < (rootBreakpoints.sm ? rootBreakpoints.sm : 576);
        case viewportSize.md:
            return vw < (rootBreakpoints.md ? rootBreakpoints.md : 768);
        case viewportSize.lg:
            return vw < (rootBreakpoints.lg ? rootBreakpoints.lg : 992);
        case viewportSize.xl:
            return vw < (rootBreakpoints.xs ? rootBreakpoints.xl : 1200);
        case viewportSize.xxl:
            return vw < (rootBreakpoints.xs ? rootBreakpoints.xxl : 1200);
    }
};
