import Swiper, {Autoplay, EffectFade, Pagination} from 'swiper';

const homeHeroContainer = document.querySelector('#home-hero .swiper');

const customPaginationElement = (swiper, current, total) => {
    const template = document.createElement('DIV');

    swiper.slides.forEach((slide, idx) => {
        const child = document.createElement('DIV');
        child.classList.add('home-swiper-pagination-bullet');

        if ((idx + 1) === current) {
            child.classList.add('swiper-pagination-bullet-active');
        } else {
            child.classList.remove('swiper-pagination-bullet-active');
        }

        child.innerText = slide.dataset?.title || '';
        template.appendChild(child);
    });

    return template.innerHTML;
};

if (homeHeroContainer) {
    const slides = homeHeroContainer.querySelectorAll('.swiper-slide');

    new Swiper(homeHeroContainer, {
        modules: [Pagination, Autoplay, EffectFade],
        autoHeight: true,
        autoplay: {
            delay: 6000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'custom',
            clickable: true,
            renderCustom: customPaginationElement,
            bulletClass: 'home-swiper-pagination-bullet'
        },
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        on: {
            afterInit: () => {
                slides.forEach((slide) => slide.classList.remove('opacity-0'));
            }
        }
    });
}
