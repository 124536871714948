export const MobileNavContainer = () => {
    const container = document.querySelector('#nav-mobile-container');

    return {
        open: () => container.classList.add('open'),
        close: () => container.classList.remove('open'),
        appendPage: (page) => {
            Array.from(container.children).forEach((child) => {
                child.classList.add('d-none');
            });
            container.appendChild(page);
        },
        removePage: (page) => {
            container.removeChild(page);
            const last = Array.from(container.children)[container.children.length - 1];
            if (last) {
                last.classList.remove('d-none');
            }
        },
        isEmpty: () => !Boolean(container.children.length)
    };
};

export const mobileNavContainer = MobileNavContainer();
