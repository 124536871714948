import Observable from '../../../_assets/scripts/Observable';

export const NavMetaSearchToggleButton = (root) => {
    const searchToggleButton = root.querySelector('.base-meta-navigation .nav-meta-search-toggle');

    if (!searchToggleButton) {
        console.warn('No meta navigation search toggle button found');
        return undefined;
    }

    const isEnabled = new Observable(false);

    const timesTemplate = document.createElement('DIV');
    timesTemplate.innerHTML = '<i class="fal fa-times text-brand-black"></i>';
    const [timesElement] = timesTemplate.children;
    const [searchElement] = searchToggleButton.children;

    const enable = () => {
        searchElement.classList.add('hidden');
        searchElement.onanimationend = (e) => {
            if (e.animationName === 'fade-out') {
                searchToggleButton.removeChild(searchElement);
                searchElement.classList.remove('hidden');
                searchToggleButton.appendChild(timesElement);
                isEnabled.setValue(true);
            }
        };
    };

    const disable = () => {
        timesElement.classList.add('hidden');
        timesElement.onanimationend = (e) => {
            if (e.animationName === 'fade-out') {
                searchToggleButton.removeChild(timesElement);
                timesElement.classList.remove('hidden');
                searchToggleButton.appendChild(searchElement);
                isEnabled.setValue(false);
            }
        };
    };

    searchToggleButton.onclick = () => {
        if (isEnabled.getValue()) {
            disable();
        } else {
            enable();
        }
    };

    return {
        element: searchToggleButton,
        enable,
        disable,
        onEnabledChange: isEnabled.onChange
    };
};
