import {portalLoginLinksStore} from '../../../_assets/scripts/stores';
import {MobileNavListEntry} from './MobileNavListEntry';
import {api} from '../../../_assets/scripts/api';
import {getEntryUrl} from '../../../_assets/scripts/requests';

const Translations = {
    'de-CH': {
        regionSelector: 'Website Wechseln',
        login: 'Händler Login',
        logout: 'Logout'
    },
    'fr-CH': {
        regionSelector: 'Changer de site',
        login: 'Login d\'agent',
        logout: 'Logout'
    }
};

export const MobileNavPagePortalLoginLinks = (root) => {
    const baseUrl = window.sessionStorage.getItem('baseUrl');
    const currentUser = window.sessionStorage.getItem('currentUser');
    const currentUserCanReadDealerDocuments = JSON.parse(window.sessionStorage.getItem('currentUserCanReadDealerDocuments'));
    const lang = window.sessionStorage.getItem('lang');

    if (!root) {
        console.warn('No root for the mobile navigation portal login links provided');
        return undefined;
    }

    portalLoginLinksStore.load();
    portalLoginLinksStore.data.onChange(async (response) => {
        root.innerHTML = '';
        const ul = document.createElement('UL');

        if (response.data) {
            const portalLoginLinks = response.data.globalSet.portalLoginLinks;
            portalLoginLinks.forEach((link) => {
                if (link.typeHandle === 'externalLink') {
                    const li = MobileNavListEntry({
                        title: link.label,
                        url: link.href,
                        skipOnClick: true,
                        isExternalLink: true,
                    });
                    const hr = document.createElement('HR');
                    hr.classList.add('my-2');
                    li.element.appendChild(hr);
                    ul.appendChild(li.element);
                }
                if (link.typeHandle === 'internalLink') {
                    const entry = link.entry.length ? link.entry[0] : undefined;
                    if (entry) {
                        const li = MobileNavListEntry({
                            title: link.label ? link.label : entry.title,
                            url: entry.url,
                            skipOnClick: true,
                        });
                        const hr = document.createElement('HR');
                        hr.classList.add('my-2');
                        li.element.appendChild(hr);
                        ul.appendChild(li.element);
                    }
                }
            });
        }

        if (currentUserCanReadDealerDocuments) {
            const dealerDocumentsEntry = await api.query({
                query: getEntryUrl({section: 'pages', type: 'dealerDocuments'})
            });

            if (dealerDocumentsEntry?.data?.entry?.id) {
                const entry = dealerDocumentsEntry.data.entry;
                const li = MobileNavListEntry({
                    title: entry.title,
                    url: entry.url,
                    skipOnClick: true,
                });
                const hr = document.createElement('HR');
                hr.classList.add('my-2');
                li.element.appendChild(hr);
                ul.appendChild(li.element);
            }
        }

        const li = MobileNavListEntry({
            title: currentUser
                ? `<i class="fal fa-sign-out me-1"></i> ${Translations[lang].logout}`
                : `<i class="fal fa-sign-in me-1"></i> ${Translations[lang].login}`,
            url: `${baseUrl}${currentUser ? 'logout' : 'login'}`,
            skipOnClick: true,
        });
        const hr = document.createElement('HR');
        hr.classList.add('my-2');
        li.element.appendChild(hr);
        ul.appendChild(li.element);

        // add region selector
        (() => {
            const regionSelectorListEntry = MobileNavListEntry({
                title: `<i class="fas fa-globe me-1"></i> ${Translations[lang].regionSelector}`,
                url: 'https://www.kuhn.com/en',
                skipOnClick: true,
                isExternalLink: true
            });
            const hr = document.createElement('HR');
            hr.classList.add('my-2');
            ul.appendChild(regionSelectorListEntry.element);
            ul.appendChild(hr);
        })();

        root.appendChild(ul);
    });
};
