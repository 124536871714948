export const chevronPosition = {
    left: 'left',
    right: 'right',
};

export const MobileNavListEntry = ({
                                       title,
                                       url,
                                       skipOnClick = false,
                                       chevronPos,
                                       anchorClasses = [],
                                       onClick,
                                       isExternalLink
                                   }) => {
    const template = document.createElement('DIV');
    template.innerHTML = `
        <li>
            <a href="${url}" class="nav-mobile-item">
                ${chevronPos === chevronPosition.left ? '<i class="fal fa-chevron-left me-2"></i>' : ''}
                <span>
                    ${title}
                </span>
                ${chevronPos === chevronPosition.right ? '<i class="fal fa-chevron-right"></i>' : ''}
            </a>
        </li>`;

    const [li] = template.children;
    const [a] = li.children;
    a.classList.add(...anchorClasses);

    if (isExternalLink) {
        a.rel = 'noopener noreferrer nofollow';
        a.target = '_blank';
    }

    a.onclick = (e) => {
        if (!skipOnClick) {
            e.preventDefault();
            onClick && onClick();
        }
    };

    return {
        element: li,
    };
};
