import Observable from './Observable';
import {api} from './api';
import {
    getDealers,
    getNav,
    getPortalLoginLinks,
    getProductsByStructIds,
    getProductStructNav,
    getTeasers
} from './requests';
import {cloneDeep} from '@apollo/client/utilities';
import {
    entryId,
    entrySectionHandle,
    entryTypeHandle,
    isEventSectionAndType,
    isHomeSectionAndType,
    isNewsSectionAndType,
    isOutsideHomeStructure,
    isPagesSectionAndProductsType,
    isProductStructSectionAndDefaultType,
    newsAndEventsPageId
} from "../../_layout/partials/navigation/MobileNavConditionals";

export const navStore = (() => {
    const data = new Observable();
    const loading = new Observable(false);

    const load = async ({id = entryId, sectionHandle = entrySectionHandle, typeHandle = entryTypeHandle} = {}) => {
        loading.setValue(true);
        if (
            isNewsSectionAndType({section: sectionHandle, type: typeHandle}) ||
            isEventSectionAndType({section: sectionHandle, type: typeHandle})
        ) {
            await loadByNewsOrEventSectionAndType({id, section: sectionHandle});
            loading.setValue(false);
            return undefined;
        }

        if (isHomeSectionAndType({section: sectionHandle, type: typeHandle})) {
            await loadByHomeSectionAndType(id);
            loading.setValue(false);
            return undefined;
        }

        if (isPagesSectionAndProductsType({section: sectionHandle, type: typeHandle})) {
            await loadByPageSectionAndProductsType(id);
            loading.setValue(false);
            return undefined;
        }

        if (isProductStructSectionAndDefaultType({section: sectionHandle, type: typeHandle})) {
            await loadByProductStructSectionAndDefaultType(id);
            loading.setValue(false);
            return undefined;
        }

        if (isOutsideHomeStructure && !isProductStructSectionAndDefaultType({
            section: sectionHandle,
            type: typeHandle
        })) {
            await loadOutsideStructure(id);
            loading.setValue(false);
            return undefined;
        }

        await loadByEntryId(id);
        loading.setValue(false);
    };

    const loadByEntryId = async (id) => {
        const result = await api.query({
            query: getNav({entryId: id})
        });

        data.setValue(result);
    };

    const loadByNewsOrEventSectionAndType = async ({id, section}) => {
        const entry = await api.query({
            query: getNav({section: section, entryId: id})
        });

        const result = cloneDeep(entry);

        const parent = await api.query({
            query: getNav({entryId: newsAndEventsPageId})
        })

        if (result.data?.entries?.length && parent.data?.entries?.length) {
            result.data.entries[0].parent = parent.data.entries[0];
            result.data.entries[0].children = [];
        }

        data.setValue(result);
    };

    const loadOutsideStructure = async (id) => {
        const entry = await api.query({
            query: getNav({entryId: id})
        });
        const home = await api.query({
            query: getNav({entryType: 'home'})
        });
        const result = cloneDeep(entry);

        if (entry.data?.entries?.length && home.data?.entries?.length) {
            result.data.entries[0].parent = home.data.entries[0];
        }

        data.setValue(result);
    };

    const loadByHomeSectionAndType = async (id) => {
        const entry = await api.query({
            query: getNav({entryId: id})
        });

        const productStructs = await api.query({
            query: getProductStructNav({level: 1})
        });

        const result = cloneDeep(entry);
        let productsEntry = null;
        if (result.data?.entries?.length && productStructs.data?.entries?.length) {
            productsEntry = result.data.entries[0].children.find((entry) => entry['__typename'] === 'pages_products_Entry');
            if (productsEntry) {
                productsEntry.children = productStructs.data.entries;
            }
        }


        data.setValue(result);
    };

    const loadByPageSectionAndProductsType = async (id) => {
        const entry = await api.query({
            query: getNav({entryId: id})
        });

        const products = await api.query({
            query: getProductStructNav({level: 1})
        });

        const result = cloneDeep(entry);

        if (result.data?.entries?.length && products.data?.entries?.length) {
            result.data.entries[0].children = products.data.entries;
        }

        data.setValue(result);
    };

    const loadByProductStructSectionAndDefaultType = async (id) => {
        const entry = await api.query({
            query: getProductStructNav({entryId: id})
        });

        const result = cloneDeep(entry);
        const hasEntry = result.data?.entries?.length;
        const nestedEntry = hasEntry ? result.data.entries[0] : null;

        if (hasEntry && nestedEntry.productStructWebPageGhost) {
            nestedEntry.children = [];
        }

        if (hasEntry && !nestedEntry.productStructWebPageGhost) {
            nestedEntry.children.forEach((child) => {
                if (child.productStructWebPageGhost) {
                    child.children = [];
                }
            });
        }

        if (hasEntry) {
            if (nestedEntry.children.length) {
                const firstChild = nestedEntry.children[0];
                if (firstChild.productStructWebPageGhost) {
                    const products = await api.query({
                        query: getProductsByStructIds({
                            structIds: nestedEntry.children.map((child) => child.id)
                        })
                    });

                    if (products.data.entries.length) {
                        const mappedLinkNodeFPRs = products.data.entries.map((entry) => {
                            if (entry.children.length && entry.children[0].children.length) {
                                const productStructLinkNodeFPR = entry.children[0].children[0].productStructLinkNodeFPR[0];
                                return {
                                    id: productStructLinkNodeFPR.id,
                                    productLabel: productStructLinkNodeFPR.productLabel
                                };
                            }

                            return {
                                id: undefined,
                                productLabel: ''
                            };
                        });

                        nestedEntry.children.forEach((child, idx) => {
                            if (mappedLinkNodeFPRs[idx].id) {
                                child.title = mappedLinkNodeFPRs[idx].productLabel;
                            } else {
                                child.destroy = true;
                            }
                        });

                        nestedEntry.children = nestedEntry.children.filter((child) => !child.destroy);
                    }
                }
            }
        }

        if (hasEntry && nestedEntry.level === 1) {
            const home = await api.query({
                query: getNav({entryType: 'home'})
            });

            nestedEntry.parent = home.data.entries[0];
        }

        data.setValue(result);
    };

    return {
        data,
        load,
        loadOutsideStructure,
        loadByHomeSectionAndType,
        loadByPageSectionAndProductsType,
        loadByProductStructSectionAndDefaultType,
        loading
    };
})();

export const portalLoginLinksStore = (() => {
    const data = new Observable();

    const load = () => {
        api.query({
            query: getPortalLoginLinks()
        }).then((result) => data.setValue(result));
    };

    return {
        data,
        load,
    };
})();

export const teaserStore = (() => {
    const data = new Observable();

    const load = ({section, transformation, limit, offset}) => {
        api.query({
            query: getTeasers({section, transformation, limit, offset})
        }).then((result) => data.setValue(result));
    };

    return {
        data,
        load,
    };
})();

export const dealerStore = (() => {
    const data = new Observable();

    const load = () => {
        api.query({
            query: getDealers()
        }).then((result) => data.setValue(result));
    };

    return {
        data,
        load,
    };
})();
