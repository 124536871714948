import {ApolloClient, createHttpLink, gql as apolloGql, InMemoryCache} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';

const httpLink = createHttpLink({
    uri: `${window.sessionStorage.getItem('baseUrl')}api`,
});

const authLink = setContext((_, {headers}) => {
    const token = window.sessionStorage.getItem('graphQlToken');

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        }
    };
});

export const api = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export const gql = apolloGql;
