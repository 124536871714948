import Swiper, {Autoplay, EffectFade, Navigation, Pagination} from 'swiper';

const sliders = document.querySelectorAll('[id^=\'kuhn-slider-\']');

const initSlider = (container, id) => {
    if (container) {
        const slides = container.querySelectorAll('.swiper-slide');

        if (!slides) {
            return;
        }

        new Swiper(container, {
            modules: [Pagination, Autoplay, EffectFade, Navigation],
            loop: true,
            pagination: {
                el: `#swiper-pagination-${id}`,
                clickable: true,
            },
            navigation: {
                nextEl: `#swiper-button-next-${id}`,
                prevEl: `#swiper-button-prev-${id}`,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            }
        });
    }
};

if (sliders?.length) {
    Array.from(sliders).forEach((slider) => {
        const container = slider.querySelector('.swiper');
        initSlider(container, slider.id);
    });
}
