import Observable from "../../../_assets/scripts/Observable";

export const MobileNavToggleButton = () => {
    const element = document.querySelector('#navbar-toggle-button');
    const icon = element.querySelector('#menuicon');
    const isOpen = new Observable(false);

    const toggle = () => {
        if (isOpen.getValue()) {
            icon.classList.remove('navbar-open');
        } else {
            icon.classList.add('navbar-open');
        }

        isOpen.setValue(!isOpen.getValue());
    };

    element.onclick = toggle;

    return {
        toggle,
        onChange: isOpen.onChange,
        closeIfOpen: () => {
            if (isOpen.getValue()) {
                toggle();
            }
        }
    };
};

export const mobileNavToggleButton = MobileNavToggleButton();
