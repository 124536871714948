import {navStore} from "../../../_assets/scripts/stores";
import {mobileNavContainer} from "./MobileNavContainer";
import {mobileNavToggleButton} from "./MobileNavToggleButton";
import {MobileNavPage} from "./MobileNavPage";
import {isViewPortMinWidth, viewportSize} from "../../../_assets/scripts/breakpoints";

navStore.load();

const body = document.body;
const root = document.querySelector('.base-navigation-mobile');

mobileNavToggleButton.onChange((isOpen) => {
    const classes = ['overflow-hidden', 'm-0', 'h-100'];
    if (isOpen) {
        body.classList.add(...classes);
    } else {
        body.classList.remove(...classes);
    }
});

mobileNavToggleButton.onChange((isOpen) => {
    if (isOpen) {
        mobileNavContainer.open();
    } else {
        mobileNavContainer.close();
    }
});

let timer = null;
window.addEventListener('resize', () => {
    if (timer) {
        clearTimeout(timer);
    }

    timer = setTimeout(() => {
        if (isViewPortMinWidth(viewportSize.lg)) {
            mobileNavToggleButton.closeIfOpen();
        }
    }, 500);
});

navStore.data.onChange((data) => {
    if (data) {
        const page = MobileNavPage({data});
        mobileNavContainer.appendPage(page.element);

        // remove class after element was rendered
        setTimeout(() => {
            page.element.classList.remove('nav-mobile-container-enter');
        }, 0);

        page.onPageRemove(({id, page: p, sectionHandle, typeHandle}) => {
            if (p) {
                p.classList.add('nav-mobile-container-enter');
                p.addEventListener('transitionend', () => {
                    mobileNavContainer.removePage(p);
                    if (mobileNavContainer.isEmpty()) {
                        navStore.load({
                            id,
                            sectionHandle,
                            typeHandle,
                        });
                    }
                });
            }
        });
    }
});

let scrollTimer = null;
window.addEventListener('scroll', () => {
    if (scrollTimer) {
        clearTimeout(scrollTimer);
    }

    scrollTimer = setTimeout(() => {
        const st = window.scrollY || document.documentElement.scrollTop;
        if (st > (2 * root.clientHeight)) {
            root.classList.add('reduced');
        } else {
            root.classList.remove('reduced');
        }
    }, 100);
}, false);
