import {gql} from "./api";

export const getProductStructNav = ({entryId, entryType, level}) => gql`
query GetProductStructNav {
  entries(section: "productStruct", ${entryId ? `id: ${entryId},` : ''} ${entryType ? `type: ${JSON.stringify(entryType)},` : ''} ${level ? `level: ${JSON.stringify(level)},` : ''}) {
    id
    title
    url
    level
    sectionHandle
    typeHandle
    parent {
      id
      title
      url
      sectionHandle
      typeHandle
    }
    children {
      id
      title
      url
      sectionHandle
      typeHandle
      children {
        id
      }
      ... on productStruct_default_Entry {
        id
        productStructWebPageGhost
        productStructLinkNodeFPR {
          ... on products_default_Entry {
            id
            productLabel
          }
        }
      }
    }
    ... on productStruct_default_Entry {
      id
      productStructWebPageGhost
    }
  }
}`;

export const getProductsByStructIds = ({structIds = []}) => gql`
query GetProductsByStructIds {
  entries(section: "productStruct", id: ${JSON.stringify(structIds)}) {
    id
    children(productStructType: "MKTG Machines") {
      ... on productStruct_default_Entry {
        children {
          ... on productStruct_default_Entry {
            productStructLinkNodeFPR {
              ... on products_default_Entry {
                id
                productLabel
              }
            }
          }
        }
      }
    }
  }
}
`;

export const getNav = ({section = 'pages', entryId, entryType, level}) => gql`
query GetNav {
  entries(section: ${JSON.stringify(section)}, ${entryId ? `id: ${entryId},` : ''} ${entryType ? `type: ${JSON.stringify(entryType)},` : ''} ${level ? `level: ${JSON.stringify(level)},` : ''}) {
    id
    title
    url
    level
    sectionHandle
    typeHandle
    parent {
      id
      title
      url
      sectionHandle
      typeHandle
    }
    children {
      id
      title
      url
      sectionHandle
      typeHandle
      children {
        id
      }
    }
  }
}`;

export const getPortalLoginLinks = () => gql`
query PortalLoginLinksQuery {
  globalSet(handle: "portalLoginLinks") {
    ... on portalLoginLinks_GlobalSet {
      portalLoginLinks {
        ... on portalLoginLinks_internalLink_BlockType {
          label
          typeHandle
          entry {
            id
            url
            title
          }
        }
        ... on portalLoginLinks_externalLink_BlockType {
          id
          label
          typeHandle
          href
        }
      }
    }
  }
}`;

export const getTeasers = ({section, transformation, limit, offset}) => gql`
query TeaserQuery {
  total: entryCount(section: ${JSON.stringify(section)})
  entries(section: ${JSON.stringify(section)}, limit: ${limit}, offset: ${offset}) {
   ... on news_news_Entry {
      id
      title
      url
      postDate
      teaserText
      newsImage {
        url(transform: "${transformation}")
        title
      }
    }
    ... on events_event_Entry {
      id
      title
      url
      postDate
      eventStartTime
      eventEndTime
      eventStartDate
      eventEndDate
      teaserText
      eventImage {
        url(transform: "${transformation}")
        title
      }
      eventLocation
    }
    typeHandle
  }
}`;

export const getDealers = () => gql`query DealerQuery {
  entries(section: "dealer") {
    ... on dealer_dealer_Entry {
      id
      dealer {
        ... on dealer_BlockType {
          id
          address1
          address2
          city
          eMail
          fax
          lat
          lng
          phone
          website
          zip
        }
      }
      title
    }
  }
}
`;

export const getEntryUrl = ({section, type}) => gql`query GetEntryUrl {
  entry(${section ? `section: ${JSON.stringify(section)},` : ''} ${type ? `type: ${JSON.stringify(type)},` : ''}) {
    id
    title
    url
  }
}
`;
