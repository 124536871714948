export const MapConfig = {
    style: [
        {
            'featureType': 'all',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'saturation': 36
                },
                {
                    'color': '#404040'
                },
                {
                    'lightness': 40
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.text.stroke',
            'stylers': [
                {
                    'visibility': 'on'
                },
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 16
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.icon',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'administrative',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 20
                }
            ]
        },
        {
            'featureType': 'administrative',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 17
                },
                {
                    'weight': 1.2
                }
            ]
        },
        {
            'featureType': 'landscape',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ebebeb'
                },
                {
                    'lightness': 20
                }
            ]
        },
        {
            'featureType': 'poi',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ebebeb'
                },
                {
                    'lightness': 21
                }
            ]
        },
        {
            'featureType': 'poi.park',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ebebeb'
                },
                {
                    'lightness': 21
                }
            ]
        },
        {
            'featureType': 'road.highway',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 17
                }
            ]
        },
        {
            'featureType': 'road.highway',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 29
                },
                {
                    'weight': 0.2
                }
            ]
        },
        {
            'featureType': 'road.arterial',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 18
                }
            ]
        },
        {
            'featureType': 'road.local',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'lightness': 16
                }
            ]
        },
        {
            'featureType': 'transit',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#f9f9f9'
                },
                {
                    'lightness': 19
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#f9f9f9'
                },
                {
                    'lightness': 17
                }
            ]
        }
    ],
    disableDefaultUi: false,
    initialCenter: {
        lat: 46.6514575,
        lng: 8.3940614,
    },
    initialZoom: 7,
    selectedIcon: `
      <svg width='31' height='41' viewBox='0 0 31 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M13.7741 40.1881C2.42271 23.7319 0.315674 22.043 0.315674 15.9951C0.315674 7.71082 7.03138 0.995117 15.3157 0.995117C23.6 0.995117 30.3157 7.71082 30.3157 15.9951C30.3157 22.043 28.2086 23.7319 16.8572 40.1881C16.1123 41.2642 14.519 41.2641 13.7741 40.1881ZM15.3157 22.2451C18.7675 22.2451 21.5657 19.4469 21.5657 15.9951C21.5657 12.5433 18.7675 9.74512 15.3157 9.74512C11.8639 9.74512 9.06567 12.5433 9.06567 15.9951C9.06567 19.4469 11.8639 22.2451 15.3157 22.2451Z' fill='#EE3126'/>
      </svg>`,
    unselectedIcon: `
      <svg width='31' height='41' viewBox='0 0 31 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M13.7741 40.1881C2.42271 23.7319 0.315674 22.043 0.315674 15.9951C0.315674 7.71082 7.03138 0.995117 15.3157 0.995117C23.6 0.995117 30.3157 7.71082 30.3157 15.9951C30.3157 22.043 28.2086 23.7319 16.8572 40.1881C16.1123 41.2642 14.519 41.2641 13.7741 40.1881ZM15.3157 22.2451C18.7675 22.2451 21.5657 19.4469 21.5657 15.9951C21.5657 12.5433 18.7675 9.74512 15.3157 9.74512C11.8639 9.74512 9.06567 12.5433 9.06567 15.9951C9.06567 19.4469 11.8639 22.2451 15.3157 22.2451Z' fill='#EE3126'/>
      </svg>`
};
