const setBaseContentMinHeight = () => {
    const content = document.querySelector('.base-content');
    const metaNavigation = document.querySelector('.base-meta-navigation');
    const navigationMobile = document.querySelector('.base-navigation-mobile');
    const footer = document.querySelector('footer');
    const footerTop = document.querySelector('.footer-top');
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

    const {height: mh} = metaNavigation.getBoundingClientRect();
    const {height: nb} = navigationMobile.getBoundingClientRect();
    const {height: f} = footer.getBoundingClientRect();
    const footerTopMargin = JSON.parse(getComputedStyle(footerTop).marginTop.replace('px', ''));

    content.style.minHeight = `${vh - (mh + nb + f + footerTopMargin)}px`;
};

setTimeout(setBaseContentMinHeight, 0);
