export const NavMetaSearchForm = (root, toggleButton) => {
    const searchFormWrapper = root.querySelector('.nav-meta-search-form-wrapper');

    if (!searchFormWrapper) {
        console.warn('No meta search form wrapper found');
        return undefined;
    }

    const metaSearchInput = searchFormWrapper.querySelector('#metaSearch');

    toggleButton.onEnabledChange((isEnabled) => {
        if (isEnabled) {
            searchFormWrapper.classList.remove('hidden', 'd-none');
            metaSearchInput.focus();
        } else {
            searchFormWrapper.classList.add('hidden');
        }
    });

    return {};
};
